import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ISelectOption } from '@shared/models/select.model';
import { PRIMARY_COLOR } from '@shared/constants/constants.const';
import { DEFAULT_MENU_TEXT_COLOR } from '@shared/constants/default-menu-text-color.const';

@Component({
  selector: 'kp-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
  @Input() public options: ISelectOption[] = [];
  @Input() public separateFirstElement: boolean = false;
  @Input() public separateLastElement: boolean = false;

  @Output() public optionClicked: EventEmitter<ISelectOption> = new EventEmitter<ISelectOption>();

  public readonly defaultIconColor: string = PRIMARY_COLOR;
  public readonly defaultTextColor: string = DEFAULT_MENU_TEXT_COLOR;
}
