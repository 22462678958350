import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'kp-description-view',
  templateUrl: './description-view.component.html',
  styleUrls: ['./description-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DescriptionViewComponent {
  @Input() public description: string;
}
