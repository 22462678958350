import { FormValidationService } from '@core/services/business/utils/form-validation.service';
import { AbstractControl, FormGroup } from '@angular/forms';
import { BaseClass } from '../base/base.class';

export abstract class BaseFormComponent extends BaseClass {
  protected constructor(protected readonly formValidationService: FormValidationService) {
    super();
  }

  public getValidatorErrorMessage(field: AbstractControl, specificErrorText?: string): string | void {
    return this.formValidationService.getValidatorErrorMessage(field, specificErrorText);
  }

  public validateAllFormFields(formGroup: FormGroup): void {
    this.formValidationService.validateAllFormFields(formGroup);
  }

  public validateFormControl(control: AbstractControl): void {
    this.formValidationService.validateFormControl(control);
  }

  public isControlInvalid(control: AbstractControl): boolean {
    return this.formValidationService.isControlInvalid(control);
  }

  public isControlInvalidReactive(control: AbstractControl): boolean {
    return this.formValidationService.isControlInvalidReactive(control);
  }
}
