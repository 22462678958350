import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'kp-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDataComponent {
  @Input() public iconKey: string = 'sadFace';
  @Input() public iconSize: string = '29px';
  @Input() public text: string = 'Извините, ничего не найдено';
  @Input() public paddingY: string = '40px';
  @Input() public iconTextGap: string = '24px';
  @Input() public fontSize: string = '18px';
  @Input() public fontWeight: string = '700';
  @Input() public color: string;
  @Input() public textColor: string;
  @Input() public iconColor: string;
}
