<div
  class="container"
  [style.padding]="paddingY + ' 0'"
>
  <kp-svg
    *ngIf="iconKey"
    [key]="iconKey"
    [size]="iconSize"
    [style.margin-bottom]="iconTextGap"
    cursor="default"
    [color]="color || iconColor"
  >
  </kp-svg>

  <div
    [ngStyle]="{
      'font-size': fontSize,
      'font-weight': fontWeight,
      color: color || textColor
    }"
  >
    {{ text }}
  </div>
</div>
